<template>
  <div class="main">
    <a-layout class="mlayout">
      <a-layout-header class="layhead">
        <top-bar msg="" showback="0" />
      </a-layout-header>
      <a-layout>
        <sider-bar />
        <a-layout-content class="adminalcontent">
          <div class="maindiv">
            <a-row>
              <a-col :span="4" class="tabletools">
                <a-button v-if="thisrule > 1" type="primary" @click="add">
                  <a-icon type="plus" /> 添加
                </a-button>
              </a-col>
              <a-col :span="16"> </a-col>
              <a-col :span="4"> </a-col>
            </a-row>
            <a-table
              class="mtable"
              :loading="loading"
              :pagination="pagination"
              :columns="columns"
              :data-source="data"
              @change="handleTableChange"
            >
              <div
                slot="expandedRowRender"
                slot-scope="record"
                style="margin: 0"
              >
                <a-table
                  :columns="innerColumns"
                  :data-source="record.list"
                  :pagination="false"
                  size="small"
                >
                  <span slot="action2" slot-scope="text, record">
                    <template v-if="thisrule == 3">
                      <a @click="onEdit(record)"><a-icon type="form" /></a>
                      <a-divider type="vertical" />
                      <a @click="onDel(record.id)"><a-icon type="delete" /></a>
                    </template>
                  </span>
                </a-table>
              </div>
              <!-- <a-table
                slot="expandedRowRender"
                :slot-scope="text"
                :columns="innerColumns"
                :data-source="record"
                :pagination="false"
              >

                <span slot="action" slot-scope="text, record">
                  <a @click="onEdit(record)">修改</a>
                  <a-divider type="vertical" />
                  <a @click="onDel(record.id)">删除</a>
                </span>
              </a-table> -->

              <span slot="normalText" class="nmitem" slot-scope="text">{{
                text
              }}</span>

              <b
                slot="blodText"
                class="nmitem"
                slot-scope="text"
                style="color: #000; font-size: 14px"
                >{{ text }}</b
              >

              <span slot="action" slot-scope="text, record">
                <template v-if="thisrule > 1">
                  <a @click="add2(record)"><a-icon type="plus-square" /></a>
                  <a-divider type="vertical" />
                </template>

                <template v-if="thisrule == 3">
                  <a @click="onEdit(record)"><a-icon type="form" /></a>
                  <a-divider type="vertical" />
                  <a @click="onUp(record.id)"><a-icon type="arrow-up" /></a>
                  <a-divider type="vertical" />
                  <a @click="onDown(record.id)"><a-icon type="arrow-down" /></a>
                  <a-divider type="vertical" />
                  <a @click="onDel(record.id)"><a-icon type="delete" /></a>
                </template>
              </span>
            </a-table>

            <a-modal
              :title="modaltitle"
              :visible="visibleAddModal"
              :confirm-loading="confirmLoading"
              ok-text="确认"
              cancel-text="取消"
              @ok="handleAddOk"
              @cancel="handleAddCancel"
            >
              <a-form :form="form" class="myform">
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="照片"
                >
                  <a-input hidden v-decorator="['photourl']" placeholder="" />
                  <a-upload
                    action=""
                    list-type="picture-card"
                    :show-upload-list="false"
                    :before-upload="
                      (file) => {
                        beforeImgUpload(file);
                      }
                    "
                  >
                    <img
                      v-if="imageUrl"
                      class="tmimage"
                      :src="imageUrl"
                      alt="avatar"
                    />
                    <div v-else>
                      <a-icon :type="loading ? 'loading' : 'plus'" />
                      <div class="ant-upload-text">上传图片</div>
                    </div>
                  </a-upload>
                </a-form-item>

                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="代码"
                >
                  <a-input
                    v-decorator="[
                      'code',
                      {
                        rules: [{ required: true, message: '请填写代码' }],
                      },
                    ]"
                    oninput="if(value.length > 50)value = value.slice(0, 50)"
                    placeholder=""
                  />
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="类名"
                >
                  <a-input
                    v-decorator="[
                      'cname',
                      {
                        rules: [{ required: true, message: '请填写类名' }],
                      },
                    ]"
                    oninput="if(value.length > 50)value = value.slice(0, 50)"
                    placeholder=""
                  />
                </a-form-item>

                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="类名(en)"
                >
                  <a-input
                    v-decorator="[
                      'encname',
                      {
                        rules: [{ required: true, message: '请填写引文类名' }],
                      },
                    ]"
                    oninput="if(value.length > 50)value = value.slice(0, 50)"
                    placeholder=""
                  />
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label=""
                  style="display: none"
                >
                  <input type="hidden" v-decorator="['mid']" />
                </a-form-item>
              </a-form>
            </a-modal>
          </div>
          <a-layout-footer class="layfoot">
            <footer-bar />
          </a-layout-footer>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import {
  fetchClass,
  addClass,
  updateClass,
  delClass,
  uploadImage,
} from "../api/index";
// const Base64 = require('js-base64').Base64
import global from "../common";
import TopBar from "../components/TopBar.vue";
import SiderBar from "../components/Sider.vue";
import FooterBar from "../components/FooterBar.vue";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

const columns = [
  {
    title: "编号",
    dataIndex: "no",
    width: 120,
    scopedSlots: { customRender: "normalText" },
  },
  {
    title: "代码",
    dataIndex: "code",
    width: 200,
    scopedSlots: { customRender: "normalText" },
  },
  // {
  //   title: "排序",
  //   dataIndex: "index",
  //   width: 120,
  //   scopedSlots: { customRender: "normalText" },
  // },
  {
    title: "作业种类",
    dataIndex: "cname",
    scopedSlots: { customRender: "blodText" },
  },
  {
    title: "操作",
    key: "action",
    width: 300,
    scopedSlots: { customRender: "action" },
  },
];

const innerColumns = [
  {
    title: "编号",
    dataIndex: "no",
    width: 120,
    scopedSlots: { customRender: "normalText" },
  },
  {
    title: "代码",
    dataIndex: "code",
    width: 200,
    scopedSlots: { customRender: "normalText" },
  },
  {
    title: "作业项目",
    dataIndex: "cname",
    scopedSlots: { customRender: "blodText" },
  },
  {
    title: "操作",
    key: "action",
    width: 200,
    scopedSlots: { customRender: "action2" },
  },
];

// const data = [];
// for (let i = 0; i < 46; i++) {
//   data.push({
//     key: i,
//     name: `Edward King ${i}`,
//     age: 32,
//     address: `London, Park Lane no. ${i}`,
//   });
// }

const formItemLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 10, offset: 6 },
};

export default {
  name: "classkind",
  components: {
    TopBar,
    FooterBar,
    SiderBar,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      data: [],
      columns,
      innerColumns,
      pagination: {},
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,

      imageUrl: "",
      uploading: false,
      fileList: [
        // {
        //   uid: '-1',
        //   name: 'xxx.png',
        //   status: 'done',
        //   url: 'http://www.baidu.com/xxx.png',
        // },
      ],

      tableData: [],
      pageTotal: 0,
      pageSize: 12,
      page: 1,

      visibleAddModal: false,
      confirmLoading: false,

      form: this.$form.createForm(this, { name: "class" }),
      formItemLayout,
      formTailLayout,
      action: "add",
      editid: 0,

      mid: 0,

      modaltitle: "添加",

      thisrule: 0,
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "class" });
    this.form.getFieldDecorator("mid", {
      initialValue: "",
      preserve: true,
    });
  },
  mounted() {
    this.fetch();
    this.thisrule = global.checkrule();
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  methods: {
    handleFileChange(info) {
      console.log(info);

      let fileList = [...info.fileList];
      // fileList = fileList.slice(-2);
      fileList = fileList.map((file) => {
        if (file.response) {
          file.url = file.response.url;
        }
        return file;
      });
      this.fileList = fileList;
    },

    beforeImgUpload(file) {
      const that = this;
      const { form } = this;
      that.uploading = true;
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        that.$message.error("你只能上传jpeg或者png文件!");
      }
      console.log("file", file);
      const isLt2M = file.size / 1024 / 1024 < 0.4;
      if (!isLt2M) {
        that.$message.error("图片必须要小于 400KB!");
        that.uploading = false;
        return false;
      }
      getBase64(file, (imageUrl) => {
        that.imageUrl = "";

        that.uploading = false;
        // console.log("this.imageUrl:", this.imageUrl);
        // can use data-binding to set

        uploadImage({ img: imageUrl, filename: file.name, kind: "photo" }).then(
          (res) => {
            console.log(res);
            let results = res.results;
            if (results.message == "1") {
              that.$message.success("上传成功");
              that.imageUrl = global.baseUrl + results.url;
              setTimeout(() => {
                // alert(that.imageUrl);
                form.setFieldsValue({
                  photourl: that.imageUrl,
                });
              });
            } else if (results.message == "0") {
              that.$message.wrong("上传失败");
            }
          }
        );
      });
      return isJpgOrPng && isLt2M;
    },

    onUp(id) {
      const that = this;
      updateClass({
        mid: id,
        move: "up",
      }).then((res) => {
        console.log(res);
        let results = res.results;
        if (results.message == "1") {
          that.$message.success("修改成功");
          that.confirmLoading = false;
          that.visibleAddModal = false;
          that.form.resetFields();
          that.fetch();
        } else if (results.message == "2") {
          that.confirmLoading = false;
        }
      });
    },
    onDown(id) {
      const that = this;
      updateClass({
        mid: id,
        move: "down",
      }).then((res) => {
        console.log(res);
        let results = res.results;
        if (results.message == "1") {
          that.$message.success("修改成功");
          that.confirmLoading = false;
          that.visibleAddModal = false;
          that.form.resetFields();
          that.fetch();
        } else if (results.message == "2") {
          that.confirmLoading = false;
        }
      });
    },
    add() {
      this.action = "add";
      this.modaltitle = "添加";
      this.editid = 0;
      this.visibleAddModal = true;
      setTimeout(() => {
        this.form.resetFields();
      });
    },

    add2(record) {
      this.action = "add2";
      this.modaltitle = "添加下级分类";
      this.visibleAddModal = true;
      setTimeout(() => {
        this.form.resetFields();
        this.form.setFieldsValue({
          mid: record.id,
        });
      });
    },

    handleAddOk() {
      this.confirmLoading = true;
      const that = this;

      this.form.validateFields((err, values) => {
        console.log("Received values of form: ", values);
        if (!err) {
          if (that.action == "edit") {
            updateClass({
              photourl: values.photourl,
              code: values.code,
              cname: values.cname,
              encname: values.encname,
              mid: values.mid,
            }).then((res) => {
              console.log(res);
              let results = res.results;
              if (results.message == "1") {
                that.$message.success("修改成功");
                that.confirmLoading = false;
                that.visibleAddModal = false;
                that.form.resetFields();
                that.fetch();
              } else if (results.message == "2") {
                that.confirmLoading = false;
              }
            });
          } else {
            console.info("success");
            addClass({
              photourl: values.photourl,
              cname: values.cname,
              encname: values.encname,
              code: values.code,
              mid: values.mid,
            }).then((res) => {
              console.log(res);
              let results = res.results;
              if (results.message == "1") {
                that.$message.success("添加成功");
                that.confirmLoading = false;
                that.visibleAddModal = false;
                that.form.resetFields();
                that.fetch();
              } else if (results.message == "2") {
                that.confirmLoading = false;
                that.$message.warning("题库名重复！");
              }
            });
          }
        } else {
          // that.$message.error("出现错误，请联系管理员！");
          // that.form.resetFields();
          that.confirmLoading = false;
        }
      });
    },

    handleAddCancel() {
      this.visibleAddModal = false;
    },

    onEdit(record) {
      this.action = "edit";
      this.modaltitle = "修改";
      this.visibleAddModal = true;
      setTimeout(() => {
        this.form.setFieldsValue({
          mid: record.id,
          code: record.code,
          cname: record.cname,
          encname: record.encname,
          photourl: record.photourl,
        });
        this.mid = record.id;
      });

        this.imageUrl = record.photourl;
        this.fileList = record.flist;

        console.log("fileList:", record.flist);
        
    },

    onDel(id) {
      console.log("del:", id);
      const that = this;
      this.$confirm({
        title: "提示",
        content: "确定删除这条题目?",
        okText: "删除",
        cancelText: "取消",
        onOk() {
          delClass({ id: id }).then((res) => {
            console.log(
              "%cdel",
              "color: #FF0000;font-size: 18px;font-weight: bold;",
              res
            );
            that.fetch();
          });
        },
        onCancel() {},
      });
    },
    onMag(id) {
      console.log("mag:", id);
    },
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination, filters, sorter);
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.page = pagination.current;
      this.pagination = pager;
      this.fetch({
        pagesize: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    },
    fetch(params = { page: this.page, pagesize: this.pageSize }) {
      let that = this;
      this.loading = true;
      // console.log(params);
      fetchClass(params).then((res) => {
        console.log(res);
        const pagination = { ...this.pagination };
        pagination.total = parseInt(res.results.total);
        pagination.pageSize = this.pageSize;
        that.loading = false;
        this.data = res.results.list;
        this.pagination = pagination;
      });
    },
    start() {
      this.loading = true;
      // ajax request after empty completing
      setTimeout(() => {
        this.loading = false;
        this.selectedRowKeys = [];
      }, 1000);
    },
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mtable {
  font-size: 14px;
}
.nmtitle {
  font-size: 12px;
}
.nmitem {
  font-size: 14px;
}
</style>
